import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import logo from "../assets/mayi_logo.png"
import DashboardIcon from "../assets/adminSidebar/Dashboard.svg"
import CancelIcon from '@mui/icons-material/Cancel';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { updateListSelect } from '../redux/user_reducer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSideBar } from '../redux/active_bar';
import { useMediaQuery } from "react-responsive"
import Dashboard from '../modules/dashboard';
import profile from "../assets/User.svg"
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import passwordChange from "../assets/Login.svg"
import order from "../assets/Clipboard.svg"
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import wallet from "../assets/Vector.svg"
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
// import { useHistory } from 'react-router-dom';
const SideBarList = () => {
    const admin =useSelector(state=>state.user.role)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [selected, setSelected] = useState();
    const role = useSelector(state => state.user.role)
    const list_select = useSelector(state => state.user.listitem)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const member=useSelector(state=>state.user.member)
    const location = useLocation()
    // const history=useHistory()
    const logout=()=>{
        // // console.log("inside logout")
        // localStorage.removeItem('authtoken')
        localStorage.clear()
        
        if(admin){
            // navigate("/login")
            // navigate('/login');
            var url = '/login';
window.history.go(-window.history.length);
window.location.href = url;
           
            // window.history.pushState(null, null, '/login');
            // window.history.pushState(null, null, '/login');
            // window.history.go(-2);
        }
        else{
            navigate("/")
        }
       
        
    }
    useEffect(()=>{
        // console.log(location.pathname)
        setSelected(location.pathname)
        dispatch(updateListSelect(location.pathname))
    },[])
    const handleListItemClick = (e, list) => {
        e.preventDefault();
        // console.log(list)
        setSelected(list);
        dispatch(updateListSelect(list))
        navigate(list)
    };

    return(
    <Box
        sx={{ width:"100%" ,}}
        role="presentation"
        onClick={()=>dispatch(updateSideBar(false))}
        onKeyDown={()=>dispatch(updateSideBar(false))}
    >
        {/* {isMobile&&<>
        <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box component="img" sx={{width:"100px" }} src={logo} />
            
            <CancelIcon sx={{color:'grey.500'}} />
            
        </Box>
        <Divider /></>} */}
        <List>
            {(role=="user"||role=='sc') && member=='member' &&
            <ListItem  disablePadding sx={{ color:list_select=="/dashboard"?"primary.light":"grey.900",borderLeft:list_select=="/dashboard"?"3px solid":"3px solid",borderColor:list_select=="/dashboard"?"primary.light":"#fff" ,height:"30px",padding:"20px 0px"}} >
                <ListItemButton component={Link} to="/dashboard" sx={{height:"30px"}} onClick={(e)=>handleListItemClick(e, '/dashboard')}>
                    <ListItemIcon sx={{minWidth:"0px",padding:"18px"}}>
                    <DashboardOutlinedIcon color={selected=="/dashboard"?'primary.light':'grey.200'}/>
                    {/* <img src={DashboardIcon} style={{width:"20px", height:"20px"}}/> */}
                        </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                </ListItemButton>
            </ListItem>}

            <ListItem  disablePadding sx={{ color:list_select=="/profile"?"primary.light":"grey.900",borderLeft:list_select=="/profile"?"3px solid ":"3px solid",borderColor:list_select=="/profile"?"primary.light" :"#fff",height:"30px",padding:"20px 0px"}} >
                <ListItemButton component={Link} to="/profile" sx={{height:"30px"}} onClick={(e)=>handleListItemClick(e, '/profile')}>
                    <ListItemIcon sx={{minWidth:"0px",padding:"20px"}}>
                    <img src={profile} style={{width:"20px", height:"20px", color:selected=="/profile"?'primary.light':'grey.200'}}/> 
                        </ListItemIcon>
                    <ListItemText primary={"Profile"} />
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding sx={{ color:list_select=="/password-change/change"?"primary.light":"grey.900",borderLeft:list_select=="/password-change/change"?"3px solid ":"3px solid" ,borderColor:list_select=="/password-change/change"?"primary.light":"#fff",height:"30px",padding:"20px 0px" }}>
                <ListItemButton component={Link} to="/password-change/change" sx={{height:"30px"}} onClick={(e)=>handleListItemClick(e, '/password-change/change')} >
                <ListItemIcon sx={{minWidth:"0px",padding:"20px"}}>
                    <img src={passwordChange} style={{width:"20px", height:"20px", color:selected=="/password-change/change'"?'primary.light':'grey.200'}}/> 
                        </ListItemIcon>
                    <ListItemText primary={"Change Password"} />
                </ListItemButton>
            </ListItem>
           {/* { role=="user" &&
            <ListItem disablePadding sx={{ color:list_select=="/checkout"?"primary.light":"grey.900",borderLeft:list_select=="/checkout"?"3px solid":"3px solid",borderColor:list_select=="/checkout"?"primary.light":"#fff" ,height:"30px",padding:"20px 0px" }}  >
                <ListItemButton component={Link} to="/checkout" sx={{height:"30px"}} onClick={(e)=>handleListItemClick(e, '/checkout')}>
                <ListItemIcon sx={{minWidth:"0px",padding:"20px"}}>
                <ShoppingBagOutlinedIcon />
                        </ListItemIcon>
                    <ListItemText primary={"Checkout"}  />
                </ListItemButton>
            </ListItem>} */}
            {(role=="user"||role=='sc') &&member=='member' &&
            <ListItem disablePadding sx={{ color:list_select=="/my_networth"?"primary.light":"grey.900",borderLeft:list_select=="/my_networth"?"3px solid":"3px solid",borderColor:list_select=="/my_networth"?"primary.light":"#fff" ,height:"30px",padding:"20px 0px" }}  >
                <ListItemButton component={Link} to="/my_networth" sx={{height:"30px"}} onClick={(e)=>handleListItemClick(e, '/my_networth')}>
                <ListItemIcon sx={{minWidth:"0px",padding:"20px"}}>
                    <img src={wallet} style={{width:"20px", height:"20px", color:selected=="/my_networth"?'primary.light':'grey.200'}}/> 
                        </ListItemIcon>
                    <ListItemText primary={"My Networth"}  />
                </ListItemButton>
            </ListItem>}
            {(role=="user"||role=='sc') &&
            <ListItem disablePadding sx={{ color:list_select=="/orderstatus"?"primary.light":"grey.900",borderLeft:list_select=="/orderstatus"?"3px solid":"3px solid",borderColor:list_select=="/orderstatus"?"primary.light":"#fff" ,height:"30px",padding:"20px 0px" }}  >
                <ListItemButton component={Link} to="/orderstatus" sx={{height:"30px"}} onClick={(e)=>handleListItemClick(e, '/orderstatus')}>
                <ListItemIcon sx={{minWidth:"0px",padding:"20px"}}>
                    <img src={order} style={{width:"20px", height:"20px", color:selected=="/orderstatus"?'primary.light':'grey.200'}}/> 
                        </ListItemIcon>
                    <ListItemText primary={"Orders"}  />
                </ListItemButton>
            </ListItem>}
            <ListItem disablePadding sx={{height:"30px",padding:"20px 0px" }}>
                <ListItemButton sx={{height:"30px"}}  onClick={logout}>
                    <ListItemIcon sx={{minWidth:"0px",padding:"20px"}}>
                        <LogoutOutlinedIcon color='primary.dark' />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItemButton>
            </ListItem>


        </List>
    </Box>)
}
export default SideBarList