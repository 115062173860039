
import { Card, CardContent, Typography, CardMedia, CardActions, Button, Box, Grid, Divider } from "@mui/material/"
import prodOne from "../assets/prodOne.png"
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from "react-redux";
import { updateAllProduct, updateCart } from "../redux/products";
import { useMediaQuery } from "react-responsive";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { json, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ayurvedic from "../assets/ayurvedic.svg"
import organic from "../assets/organic.svg"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { updateSnackBarMessage,updateSnackBarSeverity,updateSnackBarOpen } from "../redux/snackbar";
import { CurrencyRupee } from "@mui/icons-material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { styles } from "./product_list";
export const card_styles = {

    card: {},
    header: { fontSize: "16px", fontWeight: 600, },
    unit: { color: "grey.500", fontSize: "14px", },
    price: { color: "grey.500", fontSize: "14px",textDecoration:"line-through" },
    sellingPrice: { fontSize: "16px", fontWeight: 600 },
    Button: {
        textTransform:"none",
        border: "0.5px solid rgba(105, 189, 255, 1)", borderTopLeftRadius: 20,
        height:"40px",
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20, "&:hover": { bgcolor: "rgba(105, 189, 255, 1)", color: "#fff" }, display: "flex", alignItems: "center", gap: "8px", padding: "8px", fontSize: "14px",fontWeight:"600" ,width:"90px"
    }
}



const Cards = (params) => {
    const navigate = useNavigate()
    const cart = useSelector(state => state.product?.cart)
    const bg = useSelector(state => state.product?.bg)
    const id = useSelector(state => state.user.id)
    const authtoken = localStorage.getItem("authtoken")
    const dispatch = useDispatch()
    const member=useSelector(state=>state.user.member)
    useEffect(() => {
        // // console.log(product)
        getProducts()
    }, [])
    // // console.log(params.prod)
    useEffect(() => {


    }, [cart])
    const getProducts = async () => {

        await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}products/`,).then(({ data }) => {
            // console.log(data)
            const mergedArray = data?.map(detail => {
                const backgroundClr = bg.find(item => item.name === detail.name);
                return { ...detail, bgcolor: backgroundClr ? backgroundClr.color : null };
            });
            dispatch(updateAllProduct(mergedArray))
            setProduct(mergedArray)

        })
    }
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [product, setProduct] = useState()
    // // console.log(product)
    const prod = useSelector(state => state.product.Allproducts)
    useEffect(() => {
        setProduct(prod)
    }, [prod])

    const updateQuantity = async (object, condition, event) => {
        // console.log(cart)
        event.stopPropagation();
        let productUpdate=false
        let products = [...cart]
        const newCart = products.map(item => {
            // console.log(item)
            if (condition && item.product?.id == object.id && item.product.inventry_details?.available_quantity>item.quantity) {
                let updated = { ...item }
                productUpdate=true
                updated.quantity += 1
                // // console.log("if condition")
                if (item.id) {
                    CustomAxios.patch(`cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                    })
                    return updated
                }
                else {

                    return updated

                }

            }
            else if (!condition && item.product?.id == object.id) {
                productUpdate=true
                let updated = { ...item }
                updated.quantity -= 1

                if (item.id && updated.quantity >= 1) {
                    CustomAxios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                    })
                    return updated
                }
                else if (item.id && updated.quantity < 1) {
                    CustomAxios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`)
                    return updated
                }
                else {

                    return updated

                }


            }
            else if (item.product?.id == object.id){
                // console.log( item.product.inventry_details.available_quantity, item.quantity)
                if(condition && item.product.inventry_details?.available_quantity==item.quantity) {
                    productUpdate=false
                } 
                else{
                    productUpdate=true
                }
                return item
               

            }
            else{
                return item
            }

        })
        if (productUpdate){
            dispatch(updateCart(newCart.filter((item) => !item?.quantity < 1)))
            localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item?.quantity < 1)))
            // console.log("clipboard open")
            dispatch(updateSnackBarMessage("Successfully Updated the Cart!"))
            dispatch(updateSnackBarSeverity("success"))
    
            dispatch(updateSnackBarOpen(true))
        }
        else{
            dispatch(updateCart(newCart.filter((item) => !item?.quantity < 1)))
            localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item?.quantity < 1)))
            // console.log("clipboard open")
            dispatch(updateSnackBarMessage(`You are exceeding the available stock!. You can add only ${object.inventry_details?.available_quantity} quantity to your cart.`))
            dispatch(updateSnackBarSeverity("error"))
    
            dispatch(updateSnackBarOpen(true))
        }
        // console.log(newCart)
        // setProduct(products)
        

    }
    const addCart = (item, quantity,e) => {
        e.preventDefault()
        const addCart = { product: item, quantity: quantity }
        dispatch(updateCart([...cart, addCart]))
        localStorage.setItem("cart", JSON.stringify([...cart, addCart]))
        if (authtoken) {
            handleCart(item, quantity).then(() => {
                // console.log("clipboard open")
                dispatch(updateSnackBarMessage("Successfully Added to Cart!"))
                dispatch(updateSnackBarSeverity("success"))
              })
              .catch((error) => {
                dispatch(updateSnackBarMessage("You are exceeding the available stock!. You can add only 11 quantity to your cart."))
                dispatch(updateSnackBarSeverity("error"))
              });
              dispatch(updateSnackBarOpen(true))
        }
        
    }
    const handleCart = async (item, quantity) => {
        // console.log(item, quantity, id)

        await CustomAxios.post(`cart/`, {
            products: item.id,
            quantity: quantity,
            user: id, created_by: id,
            price: member=="memebr"?item.selling_price:item.price,
            totalprice: quantity * (parseFloat(member=="memebr"?item.selling_price:item.price) + Math.round(member=="memebr"?item.selling_price:item.price * (item.tax / 100))),
            updated_by: id
        })
        const response = await CustomAxios.get(`usercart/${id}`,)
        // console.log(response)
        dispatch(updateCart(response.data))
        localStorage.setItem("cart", JSON.stringify(response.data))


    }
    const cartCheck = (id) => {
        // // console.log(id)
        // // console.log(cart)
        const filt = cart?.filter(item => item.product?.id == id)
        // filt[0].quantity
        if (filt.length > 0) {
            return filt[0].quantity

        }
        else {
            return false
        }

    }
    const addCartAdding = (item) => {
        let addon = [...cart]
        // console.log(addon)

        const filt = addon.map(ct => {

            if (ct.product?.id == item.id) {
                const quantity = { ...ct }
                quantity.quantity += 1
                // // console.log(quantity)
                return quantity
                // ct.quantity+=1
                // return ct
            }
            else {
                return ct
            }
        })
        dispatch(updateCart(filt))
        localStorage.setItem("cart", JSON.stringify(filt))

        // console.log(filt)

    }
    const productDetails = (id) => {
        // console.log("product id list",id)
        navigate(`/product_details/${id}`)
    }
    const handleCopyurl = () => {
        const url = process.env.REACT_APP_URL + `/product_details/37}`
        navigator.clipboard.writeText(url)
       
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900, 
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };
    const sliderRef = useRef(null);

    const handlePrev = () => sliderRef.current.slickPrev();
    const handleNext = () => sliderRef.current.slickNext();
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
                <Typography sx={{ fontSize: {xs:"20px",sm:"20px",md:"28px"}, fontWeight: "600", lineHeight: "24px" }}>Other Products</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: "8px" }}>
                    <Button variant="contained"
                        sx={{ width: '44px', height: '36px', textAlign: 'center', lineHeight: '36px', borderRadius: '12px', background: 'grey', padding: "8px 12px", borderRadius: "4px", "&:hover": { background: 'grey' } }}
                        onClick={handlePrev}
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <Button variant="contained"
                        sx={{ width: '44px', height: '36px', textAlign: 'center', lineHeight: '36px', borderRadius: '12px', background: 'grey', padding: "8px 12px", borderRadius: "4px", "&:hover": { background: 'grey' } }}
                        onClick={handleNext}
                    >
                        <ChevronRightIcon />
                    </Button>
                </Box>
            </Box>
            <Slider ref={sliderRef} {...settings} >
                {product?.map((product) => (
                            product.status === "publish" && <Box  onClick={() => productDetails(product.id)} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "500px", gap: "24px",padding:"0px 10px",cursor:"pointer" }}>
                                <Box sx={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"100%"}}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", background: product.bgcolor }}>
                                        <Box component="img" sx={{ height: { xs: "100%", sm: "100%", md: "244px" }, width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "282px" } }} src={product.cover_details?.original} />
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                        <Typography sx={styles.productHeading}>{product.name}</Typography>
                                        <Typography sx={styles.productContent}>{product.details}</Typography>
                                        <Typography sx={styles.productSize}>Size: {product.unit}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    <Box sx={{ display: "flex", gap: "12px" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                  {authtoken&&member=='member' ? (
                                            <>
                                                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", textDecoration: "line-through" }}><CurrencyRupee sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", marginTop: "-1px" }} />{(parseFloat(product.price) + Math.round(product?.price * (product?.tax/100))).toFixed(2) }</Typography>
                                            </>
                                        ) : (
                                            <>
                                                 <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}><CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} /> {(parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)}</Typography>
                                            </>
                                        )
                                    }
                                    </div >
                                        { authtoken &&member=='member' && (<>
                                            <Divider orientation="vertical" flexItem />
                                            <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}><CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} /> { (parseInt(member=='member'?product.selling_price:product.price) + Math.round(parseInt(member=='member'?product.selling_price:product.price) * (parseInt(product.tax)/100))).toFixed(2) }</Typography>
                                            </>)}
                                    </Box>
                                    {cartCheck(product.id) ? <Button variant="contained" sx={{ width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" },display:"flex",gap:"20px" }}>
                                    <Typography onClick={(e) => updateQuantity(product, false, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}> 
                                                       <RemoveIcon sx={{fontSize:"15px",color:"#fff"}} />
                                                    </Typography>

                                                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                                        {cartCheck(product.id)}
                                                    </Typography>
                                                    <Typography onClick={(e) => updateQuantity(product, true, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                        <AddIcon  sx={{fontSize:"15px",color:"#fff"}} />
                                                    </Typography>

                                                </Button> : product.inventry_details.available_quantity>0?
                                    <Button variant="contained" sx={{ width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" } }} onClick={(e) => addCart(product, 1, e)}><ShoppingCartOutlinedIcon sx={{ marginRight: "10px" }} /> Add to Cart </Button>:<Box>Out Of Stock</Box>}
                                </Box>
                                </Box>
                                
                            </Box>
                ))}
            </Slider>
        </Box>
    )

}
export default Cards
