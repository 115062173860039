import { Box, Button, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { easeInOut, motion, useAnimation, useInView, useScroll, useTransform } from "framer-motion"
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useState } from "react";
import { landingPage_styles } from "./landing_page";
import chatbot from "./landing_assets/Chatbot.svg"
import { useDispatch, useSelector } from "react-redux";
import { updateChatBot } from "../redux/user_reducer";
import { TranslateText } from "../utils/googletranslator";

export function FrequentlyAskedQuestions() {
    const language=useSelector(state=>state.user.language)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleLoadChatbot = () => {
        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = true;
        script.src = "https://salesiq.zohopublic.in/widget"; // Zoho SalesIQ script source
    
        // Define the $zoho object with your widget code
        window.$zoho = window.$zoho || {};
        window.$zoho.salesiq = window.$zoho.salesiq || {
          widgetcode: "siqbe36070474b8445192390db783716287e3120bc3ccc7641bbab7ed07686a315f", // Replace with your actual widget code
          values: {},
          ready: function() {}
        };
    
        // Insert the script tag into the document
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
      };
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center",...landingPage_styles.positionAnimation}}>
            <Box sx={landingPage_styles.power.page_prop}>
                <Box sx={{ width: "100%", display: "flex", gap:{xs:"24px", md:"30px"}, flexDirection: "column" ,alignItems:"center"}}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap:{xs:"4px", md:"16px"}, alignItems: "center" }}>
                        {language=='en'?<motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <Typography sx={landingPage_styles.whoWeAre.headingBold}>Frequently asked {isMobile&&<br/>}questions</Typography></motion.div>:<motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Frequently asked questions</TranslateText></motion.div>}
                         
                         <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.heading}>Everything you need to know about the product and billing.</TranslateText></motion.div>
                    </Box>
                    <Box sx={landingPage_styles.frequentlyAskedQuestions.accordians}>
                        <Accordion disableGutters
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                            sx={{ boxShadow: "none",'&:before': {backgroundColor:"transparent"}}}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel1' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: "30px !important",padding:0 }}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}> What does the MAYI IQ Plan offer in terms of income streams?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>5 Streams of permanent income.</TranslateText>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disableGutters
                            expanded={expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                            sx={{ boxShadow: "none" ,'&:before': {backgroundColor:"transparent"}}}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel2' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{ minHeight: "30px !important",padding:0}}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}> Is Repurchasing required?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>No repurchase necessary at all.</TranslateText>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disableGutters
                            expanded={expanded === 'panel3'}
                            onChange={handleChange('panel3')}
                            sx={{ boxShadow: "none", '&:before': {backgroundColor:"transparent"}}}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel3' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                sx={{ minHeight: "30px !important",padding:0}}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}>  Are there complex bonus calculations associsted with the plan?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>No complicated bonus calculation.</TranslateText>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion disableGutters
                            expanded={expanded === 'panel4'}
                            onChange={handleChange('panel4')}
                            sx={{ boxShadow: "none",'&:before': {backgroundColor:"transparent"} }}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel4' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                sx={{ minHeight: "30px !important",padding:0}}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}> How can one commence their business and what options are available to get started?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>Choose products and become a member (Free Sign up), you are in the business immediately.</TranslateText>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion disableGutters
                            expanded={expanded === 'panel5'}
                            onChange={handleChange('panel5')}
                            sx={{ boxShadow: "none" ,'&:before': {backgroundColor:"transparent"}}}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel5' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                sx={{ minHeight: "30px !important",padding:0 }}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}>  When is it possible to withdraw funds and what is the condition for doing so?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>Withdraw when you have more than RS. 500.</TranslateText>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion disableGutters
                            expanded={expanded === 'panel6'}
                            onChange={handleChange('panel6')}
                            sx={{ boxShadow: "none" ,'&:before': {backgroundColor:"transparent"}}}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel6' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                sx={{ minHeight: "30px !important",padding:0 }}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}> How often are payouts made?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>Pays daily / Withdrawal Weekly.</TranslateText>
                            </AccordionDetails>

                        </Accordion>
                        <Accordion disableGutters
                            expanded={expanded === 'panel7'}
                            onChange={handleChange('panel7')}
                            sx={{ boxShadow: "none", '&:before': {backgroundColor:"transparent"}}}
                        >
                            <AccordionSummary
                                expandIcon={expanded === 'panel7' ? <RemoveCircleOutlineIcon sx={{ color: "primary.light" }} /> : <AddCircleOutlineIcon sx={{ color: "primary.light" }} />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                sx={{ minHeight: "30px !important",padding:0}}
                            >
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.headings}> How are income streams activated?</TranslateText>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TranslateText sx={landingPage_styles.frequentlyAskedQuestions.content}>The 5 streams can accumulate PV without any requirement or time frame. To withdraw the accumulated PV the package needs to be redeemed – can be done in stages without time frame constraints – high level of flexibility in the financial system.</TranslateText>
                            </AccordionDetails>

                        </Accordion>
                    </Box>

                </Box>
                {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "18px", padding:{xs:"10px 18px",md :"32px"}, bgcolor: "grey.100", alignItems: "center", width: "100%",height:isMobile?"100%":"278px" ,justifyContent:"center"}}>
                        <Box sx={{diplay:"flex",flexDirection:"column"}}>
                              <Box sx={{position:"relative",display:"flex",justifyContent:"center"}}>
                            <Box component="img" sx={!isMobile&&landingPage_styles.frequentlyAskedQuestions.bounceBox } src={chatbot}></Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", alignItems: "center" }}>
                            <Box >
                                <TranslateText sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "30px", textAlign: "center" }}>Still have questions?</TranslateText>
                                <TranslateText sx={{ fontSize: "18px", fontWeight: "400", lineHeight: "28px", textAlign: "center", color: "#3F4752" }}>Can’t find the answer you’re looking for? Please chat to our friendly team.</TranslateText>
                            </Box>
                        </Box>
                        </Box>
                      
                            <Button className="getInTouch" sx={{ maxWidth:{ md:"196px"}, width: "100%", padding: "10px 18px", gap: "8px",height:"44px", background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)", color: "black", textTransform: "none",overflow:"hidden",...landingPage_styles.buttonAnimation }} onClick={handleLoadChatbot}><TranslateText sx={{...landingPage_styles.introduction.buttonText,fontSize:"16px",lineHeight:"24px"}}>Get in touch</TranslateText></Button>
            </Box> */}
            </Box>
            
        </Box>
    )
}